<template>
  <div>
    <div class="myContainer text-neutral-700">
      <!-- filters -->
      <div class="pt-7 md:pb-4 md:pt-10">
        <p class="font-bold">{{ $store.state.transifex.common.filterBy }}</p>
        <div class="grid gap-4 pt-3 sm:grid-cols-2 md:grid-cols-4">
          <v-select
            :multiple="true"
            :placeholder="$store.state.transifex.common.candidate"
            :modelValue="selectedCandidate"
            :options="candidates"
            label="title"
            :reduce="(candidate) => candidate.id"
            :filterable="true"
            @update:modelValue="updateCandidateFilter"
            class="sm:col-span-2 md:col-span-1"
          >
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes" class="mr-3">
                <img
                  src="@/assets/vselect-arrow.svg"
                  class="w-4 h-4 flex-shrink-0 cursor-auto"
                  alt="vselect-arrow.svg"
                />
              </span>
            </template>
          </v-select>

          <v-select
            :multiple="true"
            :placeholder="$store.state.transifex.common.country"
            :modelValue="selectedCountry"
            :options="countries"
            label="name"
            :reduce="(country) => country.code"
            :filterable="true"
            @update:modelValue="updateCountryFilter"
            class="sm:col-span-2 md:col-span-1"
          >
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes" class="mr-3">
                <img
                  src="@/assets/vselect-arrow.svg"
                  class="w-4 h-4 flex-shrink-0 cursor-auto"
                  alt="vselect-arrow.svg"
                />
              </span>
            </template>
          </v-select>
          <v-select
            :multiple="true"
            :placeholder="$store.state.transifex.common.round"
            :modelValue="selectedRound"
            :options="rounds"
            label="title"
            :reduce="(round) => round.id"
            :filterable="true"
            @update:modelValue="updateRoundFilter"
            class="sm:col-span-2 md:col-span-1"
          >
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes" class="mr-3">
                <img
                  src="@/assets/vselect-arrow.svg"
                  class="w-4 h-4 flex-shrink-0 cursor-auto"
                  alt="vselect-arrow.svg"
                />
              </span>
            </template>
          </v-select>

          <v-select
            v-if="!replayPage"
            :multiple="true"
            :placeholder="$store.state.transifex.common.datetime"
            :modelValue="selectedDateTime"
            :options="dateTimes"
            label="title"
            :reduce="(datetime) => datetime.id"
            :filterable="true"
            @update:modelValue="updateDateTimeFilter"
            class="sm:col-span-2 md:col-span-1"
          >
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes" class="mr-3">
                <img
                  src="@/assets/vselect-arrow.svg"
                  class="w-4 h-4 flex-shrink-0 cursor-auto"
                  alt="vselect-arrow.svg"
                />
              </span>
            </template>
          </v-select>

          <v-select
            v-if="replayPage"
            :multiple="true"
            :placeholder="'Musical work'"
            :modelValue="selectedMusicalWork"
            :options="musicalWork"
            label="title"
            :reduce="(work) => work.id"
            :filterable="true"
            @update:modelValue="updateMusicalWorkFilter"
            class="sm:col-span-2 md:col-span-1"
          >
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes" class="mr-3">
                <img
                  src="@/assets/vselect-arrow.svg"
                  class="w-4 h-4 flex-shrink-0 cursor-auto"
                  alt="vselect-arrow.svg"
                />
              </span>
            </template>
          </v-select>
        </div>
      </div>

      <!-- Content listing -->
      <div v-for="(item, index) in calendarItems" :key="index">
        <div
          v-if="item.type === 'r'"
          class="grid gap-4 items-center py-7 border-b-[1px] border-neutral-300 lg:gap-8 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-12"
        >
        <!-- Item type = r (round) -->
        <!-- Round title -->
          <div class="col-span-3 lg:col-span-1 xl:col-span-2">
            <p class="text-neutral-800 text-2-5xl font-bold md:text-2xl">
              <template v-if="item.editor_text">{{ item.editor_text.title }}</template>
              <template v-else>{{ item.round.title }}</template>
            </p>
          </div>
          
          <!-- Round image -->
          <div class="col-span-3 sm:col-span-2 xl:col-span-4 flex items-center">
            <img
              :src="item.round.image"
              height="80"
              :alt="item.round.title"
              class="w-40 h-40 md:w-32 md:h-32"
            />
          </div>

          <!-- Round date -->
          <div class="col-span-2 sm:col-span-1 xl:col-span-3">
            <p class="text-neutral-800 font-bold text-sm md:text-xl">
              {{ item.formatted_date }}
            </p>

            <p class="text-neutral-800 inline-block font-bold text-sm mr-2 md:block md:text-xl md:mr-0">
              {{ item.formatted_time }}
            </p>

            <p
              class="inline-block text-base font-bold text-neutral-500 underline cursor-pointer hover:no-underline md:block"
              @click="showTimeZoneModal()"
            >
              {{ $store.state.transifex.common.timezone }} {{ selectedTimeZone }}
            </p>
          </div>


          <div
            class="col-span-3 justify-self-start lg:col-span-4 xl:col-span-3"
          >
            <div title="Add to Calendar" class="addeventatc z-index-5">
              {{ $store.state.transifex.schedule.addToCalendar }}
              <span class="start">{{ item.formatted_addEvent }}</span>
              <span class="timezone">{{ selectedTimeZone }}</span>
              <span class="title"
                >{{ item.round.title }}, {{ $store.state.transifex.schedule.addToCalendarTitle }}</span
              >
              <span class="description"
                >{{ item.round.title }}, {{ $store.state.transifex.schedule.addToCalendarDescription }}</span
              >
              <span class="location">{{ $store.state.transifex.schedule.addToCalendarLocation }}</span>
              <span class="client">ayQWDRnHdztJOVqDymZh130079</span>
            </div>
          </div>
        </div>

        <div
          v-if="item.type === 'p' && item.performance"
          class="grid gap-4 items-center py-7 border-b-[1px] border-neutral-300 lg:gap-8 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-12"
        >
          <!-- Item type = p (performance) -->
          <!-- Performance title cell -->
          <div class="col-span-3 lg:col-span-1 xl:col-span-2">
            <p class="text-neutral-800 text-2-5xl font-bold md:text-2xl">
              <template v-if="item.editor_text && item.status === 'ad'">{{ item.editor_text.title }}</template>
              <template v-else>{{ item.round.title }}</template>
            </p>
          </div>

          <div
          v-if="item.performance"
          class="col-span-4 sm:col-span-2 xl:col-span-4 flex items-center"
          >
            <!-- Performance image -->
            <img
              v-if="item.performance.candidate"
              :src="item.performance.candidate.image"
              height="80"
              :alt="item.performance.candidate.title"
              class="w-32 h-32"
            />
            <img
              v-else
              :src="item.performance.image"
              height="80"
              :alt="item.performance.title"
              class="w-40 h-40 md:w-32 md:h-32"
            />

            <div class="px-4 md:px-8">
              <!-- Artist name or performance title -->
              <p class="text-neutral-800 text-2xl font-bold md:text-2-5xl">
                <template v-if="item.performance.candidate">{{ item.performance.candidate.title }}</template>
                <template v-else>{{ item.performance.title }}</template>
              </p>

              <!-- Artist's age -->
              <p
                v-if="
                  item.performance.candidate && item.performance.candidate.age
                "
                class="text-lg"
              >
                {{ item.performance.candidate.age }}
                {{ $store.state.transifex.common.yearsOld }}
              </p>

              <!-- Artist's countries -->
              <p v-if="item.performance.candidate" class="text-lg whitespace-nowrap">
                {{ displayArtistCountries(item.performance.candidate.countries) }}
              </p>

              <!-- Program -->
              <ProgramPopover
                v-if="item.performance.works_movements"
                :worksMovements="item.performance.works_movements"
                :popoverID="item.performance.slug"
                :performance="item.performance"
              />
            </div>
          </div>

          <!-- Performance date -->
          <div
            class="col-span-2 sm:col-span-1 xl:col-span-3"
          >
            <p class="text-neutral-800 font-bold text-sm md:text-xl">
              {{ item.formatted_date }}
            </p>

            <p class="text-neutral-800 inline-block mr-2 font-bold text-sm md:text-xl md:mr-0">
              {{ item.formatted_time }}
            </p>

            <p
              class="inline-block text-base font-bold text-neutral-500 underline cursor-pointer hover:no-underline md:block"
              @click="showTimeZoneModal()"
            >
              {{ $store.state.transifex.common.timezone }} {{ selectedTimeZone }}
            </p>
          </div>

          <div
            class="col-span-3 justify-self-start lg:col-span-4 xl:col-span-3"
          >
            <a
              v-if="item.status === 'rd'"
              :href="item.performance.url"
              class="btn-primary flex items-center whitespace-nowrap py-2.5"
            >
              <span>{{ $store.state.transifex.schedule.watchReplay }}</span>
            </a>

            <a
              v-if="item.status === 'nr'"
              :href="item.performance.url"
              type="button"
              class="link"
            >
              {{ $store.state.transifex.schedule.comingSoon }}
            </a>
            
            <div
              v-if="item.status === 'ad'"
              title="Add to Calendar"
              class="addeventatc z-index-5"
            >
              {{ $store.state.transifex.schedule.addToCalendar }}
              <span class="start">{{ item.formatted_addEvent }}</span>
              <span class="timezone">{{ selectedTimeZone }}</span>
              <span class="title"
                >{{ item.performance.title }}, {{ $store.state.transifex.schedule.addToCalendarTitle }}</span
              >
              <span class="description"
                >{{ item.performance.title }}, {{ $store.state.transifex.schedule.addToCalendarDescription }} </span
              >
              <span class="location">{{ $store.state.transifex.schedule.addToCalendarLocation }}</span>
              <span class="client">ayQWDRnHdztJOVqDymZh130079</span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="nextOffset" class="flex justify-center -translate-y-2/4">
        <p class="flex items-center gap-2 font-bold text-primary-400 text-lg cursor-pointer px-8 bg-white" @click="showMore">
          <span>{{ $store.state.transifex.common.seeMore }}</span>
          <!-- <span class="h-4 w-4 border-x-8 border-x-transparent border-b-8 border-b-blue-600"></span> -->
          <span class="h-2 w-2 shrink-0 border-r-2 border-r-primary-400  border-b-2 border-b-primary-400 rotate-45"></span>
        </p>
      </div>

      <p v-if="calendarItems.length === 0" class="mt-8 text-center">
        {{ $store.state.transifex.common.noResultsFound }}
      </p>
    </div>
    
    <TimeZoneModal
      @changeTimeZone="changeTimeZone"
    ></TimeZoneModal>
  </div>
</template>

<script>
import {appAxios} from "@/js/app_axios";
import vSelect from "vue-select"
import moment from "moment"
import timezone from "moment-timezone"
import { appUtils } from "@/js/utils"
import TimeZoneModal from "@/components/TimeZoneModal"
import ProgramPopover from "@/components/ProgramPopover"
export default {
  name: "schedule-page",
  components: {
    vSelect,
    TimeZoneModal,
    ProgramPopover
  },
  data() {
    return {
      calendarItems: [],
      countries: [],
      rounds: [],
      candidates: [],
      dateTimes: [],
      musicalWork: [],
      selectedCountry: [],
      selectedRound: [],
      selectedCandidate: [],
      selectedDateTime: [],
      selectedMusicalWork: [],
      selectedTimeZone: null,
      nextOffset: null,
      locale: this.$store.state.languageCode === 'zh-hans' ? 'zh_cn' : this.$store.state.languageCode
    }
  },
  props: {
    apiRoute: null,
    apiFilterRoute: null,
    replayPage: null,
  },
  computed: {},
  async beforeCreate() {
    let apiFilter = this.apiFilterRoute
    if (this.replayPage) {
      apiFilter = apiFilter + "?" + "replay_page=true"
    }
    const result = await appAxios.get(apiFilter)
    if (result.data?.country) {
      this.countries = result.data.country
      this.rounds = result.data.round
      this.candidates = result.data.candidate
      this.musicalWork = result.data.musical_work
      this.dateTimes = result.data.date_time
      this.dateTimes.map((el) => {
        el.title = moment(el.title).locale(this.locale).format("LL")
      })
    }

    let searchParams = new URLSearchParams(window.location.search)
    let countryParams = searchParams.get("country")
    let roundParams = searchParams.get("round")
    let candidateParams = searchParams.get("candidate")
    let datetimeParams = searchParams.get("datetime")
    let musicalWorkParams = searchParams.get("musical_work")
    if (countryParams) this.selectedCountry = countryParams.split(",")
    if (roundParams) {
      roundParams = roundParams.split(",")
      roundParams = roundParams.map((el) => parseInt(el, 10))
      this.selectedRound = roundParams
    }
    if (candidateParams) {
      candidateParams = candidateParams.split(",")
      candidateParams = candidateParams.map((el) => parseInt(el, 10))
      this.selectedCandidate = candidateParams
    }
    if (musicalWorkParams) {
      musicalWorkParams = musicalWorkParams.split(",")
      musicalWorkParams = musicalWorkParams.map((el) => parseInt(el, 10))
      this.selectedMusicalWork = musicalWorkParams
    }
    if (datetimeParams) {
      this.selectedDateTime = datetimeParams.split(",")
    }
    if (this.replayPage) {
      searchParams.append("replay_page", "true")
    }

    let searchAPIRoute = this.apiRoute + "&" + searchParams.toString()
    await this.updateAPI(searchAPIRoute)
  },
  methods: {
    async updateCountryFilter(value) {
      this.selectedCountry = value
      await this.updateFilter(value, "country")
    },
    async updateRoundFilter(value) {
      this.selectedRound = value
      await this.updateFilter(value, "round")
    },
    async updateCandidateFilter(value) {
      this.selectedCandidate = value
      await this.updateFilter(value, "candidate")
    },
    async updateDateTimeFilter(value) {
      this.selectedDateTime = value
      await this.updateFilter(value, "datetime")
    },
    async updateMusicalWorkFilter(value) {
      this.selectedMusicalWork = value
      await this.updateFilter(value, "musical_work")
    },
    async updateFilter(selectedFilter, queryParam) {
      appUtils.updateFilter(selectedFilter, queryParam)
      let searchParams = new URLSearchParams(window.location.search)
      if (this.replayPage) {
        searchParams.append("replay_page", "true")
      }
      let searchAPIRoute = this.apiRoute + "&" + searchParams.toString()
      await this.updateAPI(searchAPIRoute)
    },
    async showMore() {
      await this.updateAPI(this.nextOffset, true)
      this.loadJS() // reload script to force addevent re-render btn add to calendar
    },
    showTimeZoneModal() {
      let modal = document.getElementById("modalTimeZone")
      modal.style.display = "block"
    },
    changeTimeZone(value) {
      this.selectedTimeZone = value
      this.calendarItems.map((el) => {
        el.formatted_date = timezone.tz(el.start_date, value).locale(this.locale).format("LL")
        el.formatted_time = timezone.tz(el.start_date, value).locale(this.locale).format("LT")
        el.formatted_addEvent = timezone.tz(el.start_date).format("L LT")
      })
    },
    async updateAPI(searchAPIRoute, nextOffset = false) {
      const { data } = await appAxios.get(searchAPIRoute)
      this.$store.commit("set_replaysNumber", data.count)
      if (data.count >= 0) {
        if (nextOffset)
          this.calendarItems = this.calendarItems.concat(data.results)
        else this.calendarItems = data.results
        // Drf return absolut next url in http, then in prod https can't response in a request http
        // So we need to use split to get relative url
        this.nextOffset = data.next
          ? data.next.split(location.host.replace("www.", ""))[1]
          : null
        this.calendarItems.map((el) => {
          el.formatted_date = moment(el.start_date).locale(this.locale).format("LL")
          el.formatted_time = moment(el.start_date).locale(this.locale).format("LT")
          el.formatted_addEvent = moment(el.start_date).format("L LT")
        })
        this.selectedTimeZone = timezone.tz.guess()
      }
    },
    loadJS() {
      let head = document.getElementsByTagName("head")[0]
      let script = document.createElement("script")
      script.src = "https://cdn.addevent.com/libs/atc/1.6.1/atc.min.js"
      head.appendChild(script)
    },
    displayArtistCountries(countriesList) {
      const countries = countriesList.map(item => {
        return item.country.name
      })

      return countries.join(', ')
    }
  }
}
</script>

<style lang="scss">
.z-index-5 {
  z-index: 5 !important;
}
</style>
