<template>
  <div v-if="calendarItems.length > 0" class="myContainer pt-7 md:pt-10">
    <div class="flex justify-between items-baseline gap-4 pb-3 lg:pb-8">
      <h3 v-html="$store.state.transifex.miniSchedule.title"></h3>
      <a
        :href="indexPageUrl"
        class="inline-block text-neutral-800 font-bold text-lg text-right underline hover:text-primary-500"
        v-html="$store.state.transifex.miniSchedule.seeAll"
      ></a>
    </div>
    <swiper
      :navigation="true"
      :modules="modules"
      slides-per-view="auto"
      :space-between="35"
      :breakpoints="swiperOptions.breakpoints"
      class="small-slide"
    >
      <swiper-slide v-for="(item, index) in calendarItems" :key="index">
        <div v-if="item.type === 'r'" class="text-neutral-700 text-xl">
          <div class="group relative inline-block w-full">
            <img
              :src="item.round.image"
              class="object-cover w-full h-40 md:h-52 xl:h-64"
              alt="item.round.image"
            />
            <div
              class="absolute inset-0 opacity-0 flex items-end transition-all ease-in-out bg-primary-400/60 group-hover:opacity-100"
            >
              <div class="addeventatc ml-1 mb-1">
                {{ $store.state.transifex.schedule.addToCalendar }}
                <span class="start">{{ item.formatted_addEvent }}</span>
                <span class="timezone">{{ selectedTimeZone }}</span>
                <span class="title"
                  >{{ item.round.title }}, {{ $store.state.transifex.schedule.addToCalendarTitle }}</span
                >
                <span class="description"
                  >{{ item.round.title }}, {{ $store.state.transifex.schedule.addToCalendarDescription }}</span
                >
                <span class="location">{{ $store.state.transifex.schedule.addToCalendarLocation }}</span>
                <span class="client">ayQWDRnHdztJOVqDymZh130079</span>
              </div>
            </div>
          </div>
          <div class="pt-2">
            <h4>
              <span v-if="item.editor_text">{{ item.editor_text.title }}</span>
              <span v-else>{{ item.round.title }}</span>
            </h4>
            <p class="text-sm font-semibold text-slate-500 lg:text-sm">
              {{ item.formatted_date }}
            </p>
          </div>
        </div>

        <div
          v-if="item.type === 'p' && item.performance"
          class="text-neutral-700 text-xl"
        >
          <div class="group relative inline-block w-full">
            <img
              :src="item.performance.image"
              class="object-cover w-full h-40 md:h-52 xl:h-64"
              alt="performance.image"
            />
            <div
              class="absolute inset-0 opacity-0 flex items-end transition-all ease-in-out bg-primary-400/60 group-hover:opacity-100"
            >
              <div class="addeventatc ml-1 mb-1">
                {{ $store.state.transifex.schedule.addToCalendar }}
                <span class="start">{{ item.formatted_addEvent }}</span>
                <span class="timezone">{{ selectedTimeZone }}</span>
                <span class="title"
                  >{{ item.performance.title }}, {{ $store.state.transifex.schedule.addToCalendarTitle }}</span
                >
                <span class="description"
                  >{{ item.performance.title }}, {{ $store.state.transifex.schedule.addToCalendarDescription }}</span
                >
                <span class="location">{{ $store.state.transifex.schedule.addToCalendarLocation }}</span>
                <span class="client">ayQWDRnHdztJOVqDymZh130079</span>
              </div>
            </div>
          </div>
          <div class="pt-2 text-sm md:text-base">
            <h4>{{ item.performance.title }}</h4>
            <p v-if="item.performance.candidate" class="font-bold">
              {{ item.performance.candidate.title }}
            </p>
            <p
              v-if="
                item.performance.candidate && item.performance.candidate.age
              "
              class="font-bold"
            >
              Age:{{ item.performance.candidate.age }}
            </p>
            <div
              v-if="
                item.performance.candidate &&
                item.performance.candidate.countries.length > 0
              "
            >
              <p>
                Nationality:
                <span
                  v-for="(itemC, index) in item.performance.candidate.countries"
                  :key="index"
                  class="leading-5 inline-block"
                >
                  {{ itemC.country.name
                  }}<span
                    v-if="
                      index >= 0 &&
                      index != item.performance.candidate.countries.length - 1
                    "
                    >,&nbsp;</span
                  ></span
                >
              </p>
            </div>
            <p class="text-sm font-semibold text-slate-500 lg:text-sm">
              {{ item.formatted_date }}
            </p>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
  <!--  <pre>{{calendarItems}}</pre>-->
</template>

<script>
import {appAxios} from "@/js/app_axios";
import moment from "moment"
import timezone from "moment-timezone"
import "vue-select/dist/vue-select.css"
import { Swiper, SwiperSlide } from "swiper/vue"
import { Navigation } from "swiper"
import "swiper/css"

export default {
  name: "mini-schedule",
  props: {
    apiRoute: null,
  },
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
    return {
      modules: [Navigation]
    }
  },
  data() {
    return {
      indexPageUrl: '/' + this.$store.state.languageCode + '/schedule/',
      selectedTimeZone: timezone.tz.guess(),
      locale: this.$store.state.languageCode === 'zh-hans' ? 'zh_cn' : this.$store.state.languageCode,
      calendarItems: [],
      swiperOptions: {
        breakpoints: {
          320: {
            slidesPerView: "auto",
            spaceBetween: 10
          },
          520: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 35
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 35
          }
        }
      }
    }
  },
  computed: {},
  async beforeCreate() {
    const { data } = await appAxios.get(this.apiRoute)
    if (data.count > 0) {
      this.calendarItems = data.results
      this.calendarItems.map((el) => {
        el.formatted_date = moment(el.start_date).locale(this.locale).format("lll")
        el.formatted_addEvent = moment(el.start_date).format("L LT")
      })
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
//.swiper:deep() {
//
//  .swiper-slide {
//    width: 80%;
//
//    @media only screen and (min-width: 520px) {
//      width: 40% !important;
//      max-width: 400px;
//    }
//
//    @media only screen and (min-width: 1024px) {
//      width: 30% !important;
//    }
//  }
//}
</style>
