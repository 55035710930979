<template>
  <div v-if="person" class="bg-[#F6F6F6]">
    <div class="relative overflow-hidden mt-7 md:mt-14">
      <div class="absolute z-0 right-0 top-0 bottom-0">
        <div class="relative h-full opacity-30">
          <div
            class="absolute inset-0 bg-gradient-to-t from-[#F6F6F6] sm:bg-gradient-to-r"
          ></div>
          <img
            src="https://medicitv-b.imgix.net/sivc/static_images/background-jury-competitors.jpg?auto=format"
            class="object-cover object-left h-full"
            alt="details-background"
          />
        </div>
      </div>
      <div
        class="myContainer relative z-10 pt-10 pb-10 mb:pb-16 lg:pt-14 lg:pb-28"
      >
        <div
          class="flex flex-col text-neutral-700 gap-4 sm:flex-row md:gap-8 lg:gap-12"
        >
          <img
            :src="person.image"
            alt="person.image"
            class="object-cover w-44 h-44 md:w-64 md:h-64"
          />
          <div>
            <div>
              <p
                class="pb-3 text-sm font-bold md:text-base lg:text-2xl"
              >
                {{ person.title }}
              </p>
              <div class="flex flex-wrap justify-between items-baseline gap-4">
                <div class="flex flex-wrap items-baseline">
                  <p v-if="person.age" class="font-bold leading-5">
                    {{ person.age }} {{ $store.state.transifex.common.yearsOld }}
                  </p>
                  <span
                    v-if="
                      (person.age && person.countries.length) ||
                      (person.age && person.website_link)
                    "
                    class="font-bold"
                    >&nbsp;-&nbsp;</span
                  >
                  <p
                    v-for="(item, index) in person.countries"
                    :key="index"
                    class="leading-5 font-bold inline-block"
                  >
                    {{ item.country.name
                    }}<span
                      v-if="index >= 0 && index != person.countries.length - 1"
                      >,&nbsp;</span
                    >
                  </p>
                  <span
                    v-if="person.countries.length && person.website_link"
                    class="font-bold"
                    >&nbsp;-&nbsp;</span
                  >
                  <a
                    v-if="person.website_link"
                    :href="person.website_link"
                    target="_blank"
                    :aria-label="person.title"
                    :title="person.title"
                    class="text-sm md:text-base"
                  >
                    {{ $store.state.transifex.common.website }} &#128279;
                  </a>
                </div>
                <SocialLinks :social-link="person.social_links"></SocialLinks>
              </div>
            </div>
            <div v-html="person.biography" class="pt-4 space-y-4 md:pt-8"></div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="person.video_file && person.video_file.url"
      class="max-w-2xl mx-auto px-8 pb-8 sm:pt-8 md:py-12 md:px-0 xl:py-16"
    >
      <PlayerStatic :video-url="person.video_file.url"></PlayerStatic>
    </div>
  </div>
</template>

<script>
import SocialLinks from "@/components/SocialLinks"
import PlayerStatic from "@/components/player/PlayerStatic"

export default {
  name: "personal-biography",
  props: {
    person: null,
  },
  components: {
    SocialLinks,
    PlayerStatic
  }
}
</script>
